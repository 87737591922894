import logo from '../images/bendabl.png'
import Fade from 'react-reveal/Fade';
import sample from '../images/bendabl.mp4';

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
      <video style={{width:'100%',objectFit:'cover',maxHeight:'1000px',minHeight:'800px'}} className='videoTag' autoPlay loop muted>
    <source src={sample} type='video/mp4' />
    </video>

        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
              <Fade duration={3000} bottom>
                <h1>
                  Robots that <span style={{color:'#fe4839',fontWeight:'700'}}>bend</span> the rules
                  
                </h1>
                </Fade>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
