export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="text-center">
         
          <div className="">
            <div className="about-text">
              <h2>About Us</h2>
              <p>We are a team of engineers with a passion about robotics of a very
                special breed: soft. We believe that the future belongs to robots
                that can reach the pinnacle of adaptability; the very change of their
                physical properties.
                </p>
                <p>
                Having accumulated deep expertise in pneumatically-actuated
                mechanisms and soft structure manufacturing, we aspire to be the
                pioneers of this new generation of robots. To push the boundaries
                of their capability. To broaden the range of applications they can
                help with. And most importantly, to flatten the learning curve of
                both developing and deploying them.
                </p>
                <p style={{fontWeight:'500'}}>     
                This future is just around the bend.</p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
